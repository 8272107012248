import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Article, About, CategoriesResponse, Service } from './data/models';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = 'https://ttbackend-production-d6df.up.railway.app/api';
  private serviceApiUrl ='https://api.toristy.com';
  private apiKey = 'CYRYUUkQZxYLFKORkvOq6JzhavmRgz';

  constructor(private http: HttpClient) { }



  // Method to fetch all articles with a limit and sorted by published date
  getArticles(): Observable<Article[]> {
    return this.http.get<Article[]>(`${this.baseUrl}/articles?populate=category,cover&pagination[limit]=45&sort=publishedAt:desc`);
  }

  // Method to fetch a single article by slug
  getArticleById(id: string): Observable<Article> {
    return this.http.get<{ data: Article }>(`${this.baseUrl}/articles/${id}?populate=*`)
      .pipe(
        map(response => response.data)
      );
  }

  // Method to populate the home page with countries, warm and cold destinations
  getHomePageContent(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/index?populate[backgroundImage]=true&populate[countries][populate][countryImage]=true&populate[warmDests][populate][countryImage]=true&populate[winterDests][populate][countryImage]=true`);
  }
  
// Get all the country data in the toristy API
getCountries(): Observable<any> {
  const url = `${this.serviceApiUrl}/countries/?apikey=${this.apiKey}`;
  return this.http.get(url).pipe(
    catchError(this.handleError)
  );
}

// In your ApiService
getServiceCategories(): Observable<CategoriesResponse> {
  const url = `${this.serviceApiUrl}/categories?apikey=${this.apiKey}`;
  return this.http.get<CategoriesResponse>(url);
}




  // Method to populate single country page with city cards & country content, by single country (Strapi country ID not countryid)
  getCountryByStrapiId(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/countries/${id}/?populate[heroImage]=true&populate[cities][populate][cityImage]=true`);
  }

  // Method to populate single city page with city cards & city content, by single city (Strapi country ID not countryid) but also collect countryid
  getCityByStrapiId(cityid: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/cities/${cityid}/?populate[heroImage]=true&[populate][country]=true`);
  }
  
   // Method to fetch about page content
   getAboutPageData(): Observable<About> {
    return this.http.get<About>(`${this.baseUrl}/about?populate=*`);
  }

  // Method to fetch all Services and sorted by category
  getServicesByCategoryId(categoryId: string): Observable<Service[]> {
    const url = `${this.serviceApiUrl}/services?apikey=${this.apiKey}&categoryid=${categoryId}`;
    return this.http.get<any>(url).pipe(
      map(response => response.services) // Extract the 'services' array from the response
    );
  }
  
  

  // Method to fetch a single service by ID
  getServiceById(id: string): Observable<any> {
    const url = `${this.serviceApiUrl}/service/${id}?apikey=${this.apiKey}`;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      errorMessage = `Server returned code ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    // Return an observable with a user-facing error message
    return throwError(errorMessage);
  }
  
}

