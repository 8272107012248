import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { CompaniesComponent } from './companies/companies.component';
import { CountryComponent } from './country/country.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ArticleComponent } from './article/article.component';
import { ServiceComponent } from './service/service.component'; 
import { CategoryComponent } from './category/category.component';

export const routes: Routes = [
    { path: 'about', component: AboutComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'country/:id/:name', component: CountryComponent },
    { path: 'service/:id/:name', component: ServiceComponent },
    { path: 'category/:categoryid/:slug', component: CategoryComponent },
    { path: 'companies', component: CompaniesComponent },
    { path: 'country', component: CountryComponent },
    { path: 'footer', component: FooterComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'navbar', component: NavbarComponent },
    { path: 'article/:id/:slug', component: ArticleComponent },
    { path: '**', component: PageNotFoundComponent}

];
