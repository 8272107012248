<div class="container mx-auto p-4" *ngIf="serviceData">
  <div class="flex flex-col md:grid md:grid-cols-3 md:gap-4">
    <!-- Service Details Column -->
    <div class="md:col-span-2">
      <h1 class="text-2xl font-bold mb-4">{{ serviceData.name }}</h1>
      
      <!-- Carousel section-->
      <div id="default-carousel" class="relative" data-carousel="static" *ngIf="serviceData.images && serviceData.images.length > 0">
        <!-- Carousel wrapper -->
        <div class="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96">
         <!-- Carousel items -->
           <!-- Carousel items -->
          <div *ngFor="let image of serviceData.images; let i = index" 
          [class]="i === currentIndex ? 'duration-700 ease-in-out' : 'hidden duration-700 ease-in-out'" 
          data-carousel-item>
          <img [src]="image.orig"
            class="block absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2" 
            alt="Service image">
          </div>

        </div>
        
        <!-- Slider indicators -->
        <div class="flex absolute bottom-5 left-1/2 z-30 space-x-3 -translate-x-1/2">
          <button type="button" 
                  [class]="currentIndex === i ? 'w-3 h-3 rounded-full bg-emerald-500' : 'w-3 h-3 rounded-full bg-white'" 
                  aria-current="false" 
                  (click)="goToImage(i)" 
                  *ngFor="let image of serviceData.images; let i = index">
          </button>
      </div>
      
        
        <!-- Slider controls -->
        <button type="button" 
                class="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" 
                data-carousel-prev
                (click)="prevImage()">
          <span class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-5 h-5 text-gray sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <span class="hidden">Previous</span>
          </span>
        </button>
        <button type="button" 
                class="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" 
                data-carousel-next
                (click)="nextImage()">
          <span class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-5 h-5 text-gray sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
            <span class="hidden">Next</span>
          </span>
        </button>
      </div>
      
      <!-- Description -->
      <div class="mb-6 mt-6  bg-emerald-50 p-4" *ngIf="serviceData.description">
        <h2 class="font-bold text-xl">Description</h2>
        <div class="text-gray-600" [innerHTML]="serviceData.description"></div>
      </div>
      
      <!-- Included -->
      <div class="mb-4 p-4" *ngIf="serviceData.included">
        <h2 class="font-bold text-xl">Included</h2>
        <div class="text-gray-600" [innerHTML]="serviceData.included"></div>
      </div>
      
      <!-- Excluded -->
      <div class="mb-4 p-4" *ngIf="serviceData.excluded">
        <h2 class="font-bold text-xl">Excluded</h2>
        <div class="text-gray-600" [innerHTML]="serviceData.excluded"></div>
      </div>
      
      <!-- Know before you go -->
      <div class="mb-4 p-4" *ngIf="serviceData.requirements">
        <h2 class="font-bold text-xl">Know before you go</h2>
        <div class="text-gray-600" [innerHTML]="serviceData.requirements"></div>
      </div>

      <!-- Cancellation details -->
      <div class="mb-4 p-4" *ngIf="serviceData.cancellation_text">
        <h2 class="font-bold text-xl">Cancellations</h2>
        <div class="text-gray-600" [innerHTML]="serviceData.cancellation_text"></div>
      </div>
      
      <!-- Location -->
      <div class="mb-4 p-4" *ngIf="serviceData.location">
        <h2 class="font-bold text-xl">Location</h2>
        <!-- Display location details -->
        <p>{{ serviceData.location.city }}, {{ serviceData.location.country }}</p>
      </div>
    </div>
    
    <!-- Calendar & Reviews Column -->

    <div class="order-last md:order-none md:col-span-1">
        <iframe [src]="iframeUrl" 
        width="50vw" height="100vh" frameborder="0" 
        style="border:0; width:100%; max-width:100%; height:100vh;" 
        allowfullscreen>
      </iframe>
    </div>
  </div>
</div> 