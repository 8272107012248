import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Service, ExtendedService } from '../data/models';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../utils.service';


@Component({
  selector: 'app-category-detail',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  services$!: Observable<ExtendedService[]>;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer
  ) {}


ngOnInit() {
  this.route.paramMap.subscribe(params => {
    const categoryid = params.get('categoryid');
    if (categoryid) {
      this.services$ = this.apiService.getServicesByCategoryId(categoryid).pipe(
        map(services => services.map(service => ({
          ...service,
          safeUrl: service.images && service.images.length > 0 ? this.getSafeUrl(service.images[0].small) : null,
          slug: this.utilsService.slugify(service.name)
        })) as ExtendedService[]) // Cast the modified services to the ExtendedService type
      );
    }
  });
}



  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
