import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { Country } from '../data/models';

@Component({
  selector: 'app-country',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit, OnDestroy {
  country$!: Observable<Country>; // Use an observable to hold the country data

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const countryId = params.get('id'); // Adjusted to match your route configuration
      if (countryId) {
        this.fetchCountry(countryId);
      } else {
        console.error('No countryId found in route parameters');
      }
    });
  }

  private fetchCountry(countryId: string): void {
    this.apiService.getCountries().subscribe((countries: Country[]) => {
      const country = countries.find((c: Country) => c.attributes.countryid === countryId);
      if (country) {
        this.country$ = of(country); // 'of' creates an Observable from the country object
      } else {
        console.error('Country not found');
      }
    }, error => {
      console.error('Error fetching countries', error);
    });
  }
  

  ngOnDestroy(): void {
    // If you subscribe within ngOnInit, consider unsubscribing here for cleanup
    // However, since we're directly assigning an Observable to country$ for use with the async pipe, manual unsubscription might not be necessary
  }
}
