<div *ngIf="(services$ | async) as services">
    <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <h2 class="text-2xl font-extrabold text-gray-900">Services by Category</h2>
            <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                <div *ngFor="let service of services" class="group relative">
                  <a [routerLink]="['/service', service.id, service.slug]">
                    <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 relative">
                        <div class="absolute inset-0 bg-black bg-opacity-20"></div>
                        <div class="absolute inset-0 flex items-center justify-center">
                            <h3 class="text-xl font-bold text-white">{{ service.name }}</h3>
                        </div>
                        <img *ngIf="service.images && service.images.length > 0" 
                             [src]="getSafeUrl(service.images[0].small)" 
                            alt="{{ service.name }}">

                    </div>
                  </a>
                </div>
            </div>
        </div>
    </div>
</div>




