<div class="container mx-auto">
    <div *ngIf="country$ | async as country" class="mb-8">
      <div class="bg-cover bg-center h-64" [ngStyle]="{'background-image': 'url(' + country.attributes.heroImage.data.attributes.formats.large.url + ')'}">
        <!-- Hero image -->
      </div>
      <div class="mt-4">
        <h2 class="text-xl font-bold">{{ country.attributes.countryName }}</h2>
        <p class="text-gray-600">{{ country.attributes.shortDesc }}</p>
        <p class="mt-2">{{ country.attributes.longDesc }}</p>
      </div>
    </div>
  </div>
  