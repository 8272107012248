import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlaneCircleXmark, faPiggyBank, faMagnifyingGlassPlus, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { Home, Category, CategoryWithSlug } from '../data/models'; 
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faPlanetCircleXmark = faPlaneCircleXmark;
  faPiggyBank = faPiggyBank;
  faMagnifyingGlassPlus = faMagnifyingGlassPlus;
  faRankingStar = faRankingStar;

  homeContent: Home = {} as Home;
  serviceCategories: CategoryWithSlug[] = []; // Use Category[] directly

  constructor(private apiService: ApiService, private utilsService: UtilsService) { }

  ngOnInit() {
    forkJoin({
      homeContent: this.apiService.getHomePageContent(),
      categoriesResponse: this.apiService.getServiceCategories()
    }).subscribe({
      next: ({ homeContent, categoriesResponse }) => {
        this.homeContent = homeContent;
  
        // Assuming categoriesResponse correctly matches the CategoriesResponse interface
        // and includes a 'categories' property that is an array of Category objects.
        // Apply slugify to each category name.
        this.serviceCategories = categoriesResponse.categories.map(category => ({
          ...category,
          slug: this.utilsService.slugify(category.name) // Add a slug property
        }));
  
        this.updateMetaTags();
      },
      error: (error) => console.error('Error fetching content:', error)
    });
  }

  
  

  updateMetaTags() {
    const headline = this.homeContent?.data?.attributes?.headline || 'Toristy Travel';
    const shortDesc = this.homeContent?.data?.attributes?.shortDesc || '';
    const imageUrl = 'https://res.cloudinary.com/dwoluaptn/image/upload/v1692946527/tt_background_8d384a23c7.jpg';
  
    this.utilsService.updateTitle(`${headline} - Toristy Travel`);
    this.utilsService.updateMetaTags([
      { name: 'description', content: shortDesc },
      { name: 'og:title', content: headline },
      { name: 'og:image', content: imageUrl },
      { name: 'og:url', content: 'https://toristy.travel/' },
      { name: 'og:type', content: 'Website' },
      { name: 'og:site_name', content: 'Toristy Travel' },
    ]);
  }
  // Wrapper method to slugify country names (and any other methods you have)
  slugifyCountryName(countryName: string): string {
    return this.utilsService.slugify(countryName);
  }

}



