<!-- Hero Section with Dynamic Background Graphic -->
<!-- Only render the div if backgroundImage data is available -->
<div *ngIf="homeContent?.data?.attributes?.backgroundImage?.data?.attributes?.formats?.large?.url as bgImageUrl" class="relative overflow-hidden text-center" style="height: 500px;">
  <div [style.background]="'url(' + bgImageUrl + ') no-repeat center center / cover'" class="absolute inset-0"></div>

  <!-- Dark Overlay -->
  <div class="absolute inset-0 bg-black opacity-50"></div>

  <!-- Max Width Container -->
  <div class="max-w-7xl mx-auto h-full flex items-center justify-center">
    <div *ngIf="homeContent?.data?.attributes" class="flex flex-col items-center justify-center z-10 w-full">
      <!-- Strapi Headline -->
      <div class="mb-8" *ngIf="homeContent?.data?.attributes?.headline">
        <h1 class="text-4xl font-bold text-white">{{ homeContent.data.attributes.headline }}</h1>
      </div>

      <!-- 'Why Toristy?' Headline -->
      <div class="text-center mb-4">
        <h2 class="text-2xl font-bold text-white">Why Toristy?</h2>
      </div>

      <!-- Icons and Text Container -->
      <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 px-4 w-full">
        <div class="text-center">
          <div class="mx-auto h-12 w-12 text-white">
            <fa-icon [icon]="faPlanetCircleXmark" class="fa-lg"></fa-icon>
          </div>
          <p class="text-white mt-2">You'll receive a full refund if you cancel at least 24 hours in advance of most experiences.</p>
        </div>

        <div class="text-center">
          <div class="mx-auto h-12 w-12 text-white">
            <fa-icon [icon]="faPiggyBank" class="fa-lg"></fa-icon>
          </div>
          <p class="text-white mt-2">Your money is kept safe until the service is delivered</p>
        </div>

        <div class="text-center">
          <div class="mx-auto h-12 w-12 text-white">
            <fa-icon [icon]="faMagnifyingGlassPlus" class="fa-lg"></fa-icon>
          </div>
          <p class="text-white mt-2">We search for the best unique experiences</p>
        </div>

        <div class="text-center">
          <div class="mx-auto h-12 w-12 text-white">
            <fa-icon [icon]="faRankingStar" class="fa-lg"></fa-icon>
          </div>
          <p class="text-white mt-2">We only work with the best suppliers</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="homeContent?.data?.attributes?.warmDests?.data; let warmDests">
  <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h2 class="text-2xl font-extrabold text-gray-900">Warm vacation experiences</h2>
          <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              <div *ngFor="let dest of warmDests" class="group relative">
                <a [routerLink]="['/country', dest.attributes.countryid, dest.attributes.countryName]">
                  <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 relative">
                      <div class="absolute inset-0 bg-black bg-opacity-20"></div>
                      <div class="absolute inset-0 flex items-center justify-center">
                          <h3 class="text-xl font-bold text-white">{{ dest.attributes.countryName }}</h3>
                      </div>
                      <img *ngIf="dest.attributes.countryImage.data.attributes.formats.small"
                          [src]="dest.attributes.countryImage.data.attributes.formats.small.url"
                          alt="{{ dest.attributes.countryName }}" class="w-full h-full object-center object-cover">
                  </div>
                </a>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- Winter Vacation Experiences Section -->
<div *ngIf="homeContent?.data?.attributes?.winterDests?.data; let winterDests">
  <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h2 class="text-2xl font-extrabold text-gray-900">Winter vacation experiences</h2>
          <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              <div *ngFor="let dest of winterDests" class="group relative">
                <a [routerLink]="['/country', dest.attributes.countryid, dest.attributes.countryName]">
                  <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 relative">
                      <div class="absolute inset-0 bg-black bg-opacity-20"></div>
                      <div class="absolute inset-0 flex items-center justify-center">
                          <h3 class="text-xl font-bold text-white">{{ dest.attributes.countryName }}</h3>
                      </div>
                      <img *ngIf="dest.attributes.countryImage.data.attributes.formats.small"
                          [src]="dest.attributes.countryImage.data.attributes.formats.small.url"
                          alt="{{ dest.attributes.countryName }}" class="w-full h-full object-center object-cover">
                    </div>
                </a> 
              </div>
          </div>
      </div>
  </div>
</div>


<div *ngIf="homeContent?.data?.attributes?.countries?.data; let topCountries">
  <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h2 class="text-2xl font-extrabold text-gray-900">Top Countries</h2>
          <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              <div *ngFor="let country of topCountries | slice:0:16" class="group relative">
                <a [routerLink]="['/country', country.attributes.countryid, slugifyCountryName(country.attributes.countryName)]">
                  <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 relative">
                      <div class="absolute inset-0 bg-black bg-opacity-20"></div>
                      <div class="absolute inset-0 flex items-center justify-center">
                          <h3 class="text-xl font-bold text-white">{{ country.attributes.countryName }}</h3>
                      </div>
                     
                      <img *ngIf="country.attributes.countryImage.data.attributes.formats.small"
                          [src]="country.attributes.countryImage.data.attributes.formats.small.url"
                          alt="{{ country.attributes.countryImage.data.attributes.alternativeText }}"
                          class="w-full h-full object-center object-cover">
                        
                  </div>
                </a>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Service Categories Section -->
<div *ngIf="serviceCategories.length > 0" class="service-categories">
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
       <h2 class="text-2xl font-extrabold text-gray-900">Service Categories</h2>
       <div class="category-rows flex flex-wrap gap-2">
          <a *ngFor="let category of serviceCategories"
            [routerLink]="['/category', category.categoryid, category.slug]"
            class="text-xs border-2 rounded border-teal-700 px-2 py-1 hover:border-emerald-500 hover:bg-emerald-500 hover:text-white transition-colors cursor-pointer">
            {{ category.name }}
           </a>
        </div>
      </div>
    </div>
  </div> 








