<header class="text-gray-600 body-font">
    <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <a routerLink="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
       <img src="https://res.cloudinary.com/dwoluaptn/image/upload/v1692946377/logo_square_055c833489.png" width="60" alt="Toristy Logo">
      </a>
      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <a routerLink="/country" routerLinkActive="active" class="mr-5 hover:text-amber-500">Countries</a>
        <a routerLink="/about" routerLinkActive="active" class="mr-5 hover:text-amber-500">About</a>
      </nav>
    </div>
  </header>

